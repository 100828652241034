const formOperationsMixin = {
  methods: {
    async executeWithFormValidation (process) {
      const result = await this.$refs.form.validate()
      if (result) {
        process()
      }
    },
    async create (url, model, redirectTo) {
      try {
        this.showLoader()
        const response = await this.post(url, model)
        if (response && response.status === 201) {
          if (redirectTo) {
            this.$router.push({ name: redirectTo })
          }
          this.createSuccessNotify()
          return response
        } else {
          this.createFailNotify()
        }
      } catch (error) {
        if (error.response && error.response.status === 422) {
          if (error.response.data && error.response.data.errors) {
            this.$refs.form.setErrors(error.response.data.errors)
          } else {
            this.$refs.form.setErrors(error.response.data)
          }
          this.invalidFields()
        } else {
          this.createFailNotify()
        }
        throw error
      } finally {
        this.hideLoader()
      }
    },
    async update (url, model) {
      try {
        this.showLoader()
        const response = await this.put(url, model)
        if (response && response.status === 200) {
          this.updateSuccessNotify()
          return response
        } else {
          this.updateFailNotify()
        }
      } catch (error) {
        if (error.response && error.response.status === 422) {
          this.invalidFields()
          this.$refs.form.setErrors({ ...error.response.data })
        } else {
          this.updateFailNotify()
        }
        throw error
      } finally {
        this.hideLoader()
      }
    },
    async remove (url, id) {
      try {
        this.showLoader()
        const response = await this.delete(url, id)
        if (response && response.status === 200) {
          this.deleteSuccessNotify()
        }
        return response
      } catch (error) {
        const response = error.response
        if (response && response.status === 422) {
          if (response.data.error) {
            this.showError(response.data.error)
          } else {
            this.showError()
          }
        } else {
          this.showError()
        }
        throw error
      } finally {
        this.hideLoader()
      }
    },
    getFormData () {
      return Object.keys(this.model).reduce((formData, key) => {
        formData.append(key, this.model[key])
        return formData
      }, new FormData())
    }
  }
}

export default formOperationsMixin
