<template>
  <v-row justify="center">
    <v-dialog
      v-model="isActive"
      persistent
      max-width="290">
      <v-card>
        <v-card-title class="headline">
          {{ title }}
        </v-card-title>
        <v-card-text>
          {{ description }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="confirm"
          >
            Aceptar
          </v-btn>
          <v-btn
            color="red"
            text
            @click="close"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  name: 'ConfirmDialog',
  props: {
    isActive: {
      default: false,
      type: Boolean
    },
    title: {
      default: null,
      type: String
    },
    description: {
      default: null,
      type: String
    }
  },
  methods: {
    confirm () {
      this.$emit('onConfirm')
    },
    close () {
      this.$emit('onClose')
    }
  }
}
</script>
