const crudServiceMixin = {
  methods: {
    post (url, model) {
      return this.$axiosFiseApi.post(url, model)
    },
    delete (url, id) {
      return this.$axiosFiseApi.delete(url + '/' + id)
    },
    get (url, params) {
      return this.$axiosFiseApi.get(url, params)
    },
    getById (url, id) {
      return this.$axiosFiseApi.get(url + '/' + id)
    },
    put (url, model) {
      return this.$axiosFiseApi.put(url, model)
    }
  }
}
export default crudServiceMixin
