<template>
  <fair-layout v-if="fair" :fair-id="fair.id">
    <template v-slot:banner>
      <banner
        v-if="fair"
        :title="fair.name"
        type="banner--admin"
        :options="{padding: '30px'}">
      </banner>
    </template>
    <template v-slot:content>
      <div class="px-7">
        <div class="row">
          <div class="col-12 d-flex align-center justify-space-between">
            <h2>Gestor de Usuarios Administrador Expositor App Móvil</h2>
            <v-btn
              color="secondary"
              class="text--primary text-none elevation-0"
              @click="openAdminExpositorModal()"
              dark>
              Nuevo
            </v-btn>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <v-text-field
              v-model="params.filterName"
              label="Nombres/Apellidos"
              v-on:keyup.enter="search"
              outlined
              dense
              single-line
              hide-details
            ></v-text-field>
          </div>
          <div class="col-6">
            <v-text-field
              v-model="params.filterEmail"
              label="Email"
              v-on:keyup.enter="search"
              outlined
              dense
              single-line
              hide-details
            ></v-text-field>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <v-select
              v-model="params.filterCompany"
              :items="companies"
              item-text="name"
              item-value="id"
              outlined
              dense
              label="Empresa"
            ></v-select>
          </div>
          <div class="col-6">
            <v-select
              v-model="params.filterRole"
              :items="roles"
              item-text="name"
              item-value="id"
              outlined
              dense
              label="Tipos de Usuario"
            ></v-select>
          </div>
        </div>
        <div class="row">
          <div class="col-12 d-flex justify-end">
            <v-btn
              color="primary"
              @click="search"
              class="white--text text-none elevation-0 mr-2">
              <v-icon
                left
                dark>
                fa-search
              </v-icon>
              Buscar
            </v-btn>
            <v-btn
              color="default"
              @click="clean"
              class="text-none elevation-0 mr-2">
              <v-icon
                left
                dark>
                fa-broom
              </v-icon>
              Limpiar
            </v-btn>
            <v-btn
              color="primary"
              @click="exportExcel"
              class="white--text text-none elevation-0">
              <v-icon
                left
                dark>
                fa-file-excel
              </v-icon>
              Exportar
            </v-btn>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <data-table ref="adminExpositors" :url="url" :params="params" :headers="headers">
              <template v-slot:item.isActive="{ item }">
                <v-switch class="ma-0"
                          v-if="item.roleId === roleId"
                          v-model="item.isActive"
                          dense
                          hide-details
                          @click="onDisableUser(item)"
                ></v-switch>
              </template>
            </data-table>
          </div>
        </div>
        <admin-expositor-modal ref="adminExpositorModal" :companies="companies" :fairId="fair.id" @onClose="onClose"/>
        <confirm-dialog
          :title="'Confirmación'"
          :description="`¿Deseas ${(Boolean(selectedUser.isActive) ? 'activar' : 'desactivar')}  el usuario?`"
          @onClose="closeDisableExpositorUserModal()"
          @onConfirm="disableExpositorUser()"
          :is-active="isConfirmDisableUserActive"
        />
      </div>
    </template>
  </fair-layout>
</template>
<script>
import FairLayout from '@/layouts/FairLayout'
import DataTable from '@/components/data-table/DataTable'
import crudServiceMixin from '@/mixins/crudServiceMixin'
import { FAIRS_URL, ADMIN_EXPOSITOR_URL } from '@/constants/ServicesConstants'
import rolesConstants from '@/constants/RolesConstants'
import headers from '@/views/private/admin-expositors/data/Headers'
import sessionMixin from '@/mixins/sessionMixin'
import formOperationsMixin from '@/mixins/formOperationsMixin'
import loaderMixin from '@/mixins/loaderMixin'
import notificationMixin from '@/mixins/notificationMixin'
import AdminExpositorModal from '@/views/private/admin-expositors/components/AdminExpositorModal'
import Banner from '@/components/banner/Banner'
import ConfirmDialog from '@/components/confirm-dialog/ConfirmDialog'

export default {
  beforeRouteEnter (to, from, next) {
    const user = sessionMixin.methods.getUser()
    if (sessionMixin.methods.isSuperAdmin(user.roleId)) {
      next()
    } else {
      next({ path: '/error-403' })
    }
  },
  components: {
    FairLayout,
    Banner,
    ConfirmDialog,
    DataTable,
    AdminExpositorModal
  },
  data () {
    return {
      headers,
      url: ADMIN_EXPOSITOR_URL + this.$route.params.fairId,
      params: {
        filterName: '',
        filterEmail: '',
        filterCompany: '',
        filterRole: ''
      },
      companies: [],
      roles: [],
      fair: null,
      roleId: null,
      selectedUser: {},
      isConfirmDisableUserActive: false
    }
  },
  methods: {
    isActive (isActive) {
      return Boolean(isActive)
    },
    clean () {
      this.params.filterName = null
      this.params.filterEmail = null
      this.params.filterCompany = null
      this.params.filterRole = null
      this.search()
    },
    onClose () {
      this.$refs.adminExpositors.getDataFromApi()
    },
    openAdminExpositorModal (item) {
      this.$refs.adminExpositorModal.open(item)
    },
    search () {
      this.$refs.adminExpositors.getDataFromApi()
    },
    onDisableUser (item) {
      this.selectedUser = item
      this.isConfirmDisableUserActive = true
    },
    disableExpositorUser () {
      this.isConfirmDisableUserActive = false
      this.update(ADMIN_EXPOSITOR_URL + 'enable', this.selectedUser).then(() => {
        this.$refs.adminExpositors.getDataFromApi()
      })
    },
    closeDisableExpositorUserModal () {
      this.isConfirmDisableUserActive = false
      this.selectedUser.isActive = !this.selectedUser.isActive
    },
    exportExcel () {
      this.showLoader()
      this.get(ADMIN_EXPOSITOR_URL + this.fair.id + '/excel', { responseType: 'blob' })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'Expositores.xlsx')
          document.body.appendChild(link)
          this.preload = false
          link.click()
          this.hideLoader()
        }).catch((error) => {
          this.showError(error)
          this.hideLoader()
        })
    },
    async getFair () {
      const response = await this.get(FAIRS_URL + '/' + this.$route.params.fairId)
      this.fair = response.data
    },
    async getCompanies () {
      const response = await this.get(FAIRS_URL + '/' + this.$route.params.fairId + '/companies')
      this.companies = response.data
    },
    async getRoles () {
      const response = await this.get(ADMIN_EXPOSITOR_URL + 'roles')
      this.roles = response.data
    }
  },
  async created () {
    this.roleId = rolesConstants.expositorAdmin.code
    try {
      this.showLoader()
      await this.getFair()
      await this.getCompanies()
      await this.getRoles()
      this.hideLoader()
    } catch {
      this.hideLoader()
      this.showError()
    }
  },
  mixins: [
    crudServiceMixin,
    formOperationsMixin,
    loaderMixin,
    notificationMixin
  ],
  name: 'AdminExpositors'
}
</script>
