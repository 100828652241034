const headers = [
  {
    text: 'Nombre Completo',
    value: 'name',
    sortable: false
  },
  {
    text: 'Email',
    value: 'username',
    sortable: false
  },
  {
    text: 'Rol',
    value: 'role.name',
    sortable: false
  },
  {
    text: 'Empresa',
    value: 'company.name',
    sortable: false
  },
  {
    text: 'Activo',
    value: 'isActive',
    sortable: false
  }
]
export default headers
