import eventHub from '@/eventhub'

export default {
  methods: {
    showLoader () {
      eventHub.$emit('show_loader')
    },
    hideLoader () {
      eventHub.$emit('hide_loader')
    }
  }
}
