<template>
  <v-row justify="center">
    <v-dialog v-model="isActive" max-width="500px" persistent scrollable>
      <v-card>
        <v-card-title>
          <span class="headline">
            Nuevo Administrador expositor
          </span>
        </v-card-title>
        <v-card-text class="mt-10">
          <validation-observer ref="form">
            <v-container >
              <div class="row">
                <div class="col-12 custom-col">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Nombre Completo"
                    vid="name"
                    rules="required|max:80"
                  >
                    <v-text-field
                      v-model="model.name"
                      type="text"
                      outlined
                      dense
                      :error-messages="errors"
                      label="Nombre Completo"
                      required
                    />
                  </validation-provider>
                </div>
                <div class="col-12 custom-col">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Email"
                    vid="email"
                    rules="required|email|max:80"
                  >
                    <v-text-field
                      v-model="model.email"
                      type="email"
                      outlined
                      dense
                      :error-messages="errors"
                      label="Email"
                      required
                    />
                  </validation-provider>
                </div>
                <div class="col-12 custom-col">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Confirmar Email"
                    vid="email_confirmation"
                    rules="required|email|max:80|confirmed:email"
                  >
                    <v-text-field
                      v-model="model.email_confirmation"
                      type="email"
                      outlined
                      dense
                      :error-messages="errors"
                      label="Confirmar Email"
                      required
                    />
                  </validation-provider>
                </div>
                <div class="col-12 custom-col">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Empresa"
                    vid="companyId"
                    rules="required"
                  >
                    <v-select
                      v-model="model.companyId"
                      :items="companies"
                      item-text="name"
                      item-value="id"
                      outlined
                      dense
                      :error-messages="errors"
                      label="Empresa"
                      required
                    />
                  </validation-provider>
                </div>
              </div>
            </v-container>
          </validation-observer>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="saveAdminExpositorUser">
            Aceptar
          </v-btn>
          <v-btn color="red" text @click="close">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import crudServiceMixin from '@/mixins/crudServiceMixin'
import loaderMixin from '@/mixins/loaderMixin'
import notificationMixin from '@/mixins/notificationMixin'
import { ADMIN_EXPOSITOR_URL } from '@/constants/ServicesConstants'
import formOperationsMixin from '@/mixins/formOperationsMixin'

export default {
  name: 'AdminExpositorModal',
  props: {
    fairId: {
      type: Number
    },
    companies: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      isActive: false,
      model: {
        name: null,
        email: null,
        email_confirmation: null,
        companyId: null
      }
    }
  },
  methods: {
    close () {
      this.isActive = false
      this.clean()
    },
    clean () {
      this.$refs.form.reset()
      this.model = {
        name: null,
        email: null,
        email_confirmation: null,
        companyId: null
      }
    },
    open () {
      this.isActive = true
    },
    saveAdminExpositorUser () {
      this.executeWithFormValidation(() => {
        this.model.fairId = this.fairId
        const formData = this.getFormData()
        const url = ADMIN_EXPOSITOR_URL.substring(0, ADMIN_EXPOSITOR_URL.length - 1)
        this.create(url, formData).then(() => {
          this.close()
          this.$emit('onClose')
        })
      })
    }
  },
  mixins: [
    crudServiceMixin,
    formOperationsMixin,
    notificationMixin,
    loaderMixin
  ]
}
</script>
<style scoped>
  .custom-col{
    padding: 0px !important;
  }
</style>
